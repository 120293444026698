import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Page,
  Heading,
  ImageContainer,
  HomeLink,
  Description,
  ImageWrapper,
} from './NotFound.styled';

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query allOopsJson {
      oopsJson {
        title
        description
        image {
          base
        }
      }
    }
  `);
  const { title, description, image } = data.oopsJson;
  return (
    <Page>
      <Heading>{title}</Heading>
      <Description>{description}</Description>
      <ImageContainer>
        <ImageWrapper filename={image.base} alt="404" />
      </ImageContainer>
      <HomeLink to="/">Home</HomeLink>
    </Page>
  );
};
export default NotFound;
