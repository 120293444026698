import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from '@components/Image';

const Page = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.themeColor};
  margin-top: 60px;
  font-size: ${({ theme }) => theme.fontSizes.heading};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin-top: 20px;
  font-size: ${({ theme }) => theme.fontSizes.subHeading};
`;

const ImageContainer = styled.figure`
  margin: 3em auto;
  transform: translateX(-20px);
  width: 384px;
  height: 218px;
  cursor: none;
`;

const ImageWrapper = styled(Image)`
  * {
    margin-top: 0;
  }
`;

const HomeLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.accentYellow};
  height: auto;
  width: auto;
  margin: 0 auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.4em 2em;
  border-radius: 3em;
  min-width: 180px;
  font-size: 1.5em;
  line-height: 1;
`;

export { HomeLink, ImageContainer, Heading, Page, Description, ImageWrapper };
