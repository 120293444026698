import React from 'react';
import NotFound from '@containers/NotFound';
import PageLayout from '@containers/PageLayout';
import SEO from '@components/seo';

const NotFoundPage = () => (
  <PageLayout hideHeader title="404: Not found">
    <NotFound />
  </PageLayout>
);

export default NotFoundPage;
